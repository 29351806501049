import useTranslation from 'translations/hooks/useTranslation'
import TrackingUtils from 'utils/tracking'

const JobAdTcc = () => {
  const { t } = useTranslation()

  const trackUpsell = () => {
    TrackingUtils.event('ad-tcc-2025')
  }

  return (
    <div key="job-item-tcc25" className="container job-container">
      <a
        href="https://bit.ly/tccpretlak"
        target="_blank"
        onClick={trackUpsell}
        className="job job--level-3 job--with-logo job--custom-paid job-custom-paid--tcc"
      >
        <div className="job__logo-wrapper">
          <img
            loading="lazy"
            src="/images/paid/tcc/logo@2x.png"
            className="job__logo"
            alt="Two Coffee Champs"
          />
        </div>

        <div className="job__content-left">
          <h2 className="job__title">
            Podťe s nami na festival kávy Two Coffee Champs '25
          </h2>
          <h2 className="job__subtitle">30. - 31. máj 2025, BRATISLAVA</h2>
          <div className="pills-wrapper">
            <span className="pill pill--tag pill--small pill--link">
              #80+ Vystavovateľov
            </span>
            <span className="pill pill--tag pill--small pill--link">#Prednášky</span>
            <span className="pill pill--tag pill--small pill--link">#Workshopy</span>
            <span className="pill pill--tag pill--small pill--link">#Degustácie</span>
            <span className="pill pill--tag pill--small pill--link">#Hudba</span>
          </div>
        </div>
      </a>
    </div>
  )
}

export default JobAdTcc
